import React, { useEffect, useState } from "react";
import LoadContent from "components/LoadContent";
import { useSelector } from "react-redux";
import Colors from "./components/colors/Colors";
import CountryOfManufacture from "./components/countryManufactur/CountryOfManufacture";
import Cost from "./components/cost/Cost";
import CloseIcon from "assets/SVG/CloseSvg";
import Filter from "./components/filter";
import langData from "lang/data";
import { ReactComponent as FilterIcon } from "assets/SVG/filterIcon.svg";
import { useSearchParams } from "react-router-dom";

const Attributes = ({
  styles,
  setCurrentPage,
  filters,
  loading,
  currentLanguage,
}) => {
  const [cost, setCost] = useState(false);
  const [color, setColor] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [manufactures, setManufactures] = useState(false);
  const [attribute, setAtribute] = useState(false);
  const [openModal, setOpenModal] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [discount, setDiscount] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [priceDisabled, setPriceDisabled] = useState(false);
  const [activeAttribute, setActiveAtribute] = useState([]);

  let activeBrands = searchParams.has("b")
    ? JSON.parse("[" + searchParams.get("b") + "]")
    : [];

  let activeAttributes = searchParams.has("a")
    ? searchParams.get("a").split(",")
    : [];
  const handleSelectBrand = (item) => {
    let updatedBrands;
    const brand_id = item?.id;
    if (activeBrands.includes(brand_id)) {
      updatedBrands = activeBrands.filter((id) => id !== brand_id);
    } else {
      updatedBrands = [...activeBrands, brand_id]; 
    }

    searchParams.delete("b");

    if (updatedBrands.length > 0) {
      searchParams.append("b", updatedBrands.join(","));
    }
    setSearchParams(searchParams);
  };
  const handleSelectSorting = (sort) => {
    searchParams.delete("page");

    if (searchParams.get("s") === sort) {
      searchParams.delete("s");
    } else {
      searchParams.set("s", sort); 
    }
    setSearchParams(searchParams);
  };
  const handleSelectAttribute = (item, value) => {
    searchParams.delete("page");
    console.log(item);
    
    let attribute = `${item?.attribute_id}_${value?.id}`;

    let currentAttributes = searchParams.get("a");

    let attributesArray = [];

    if (currentAttributes) {
      attributesArray = currentAttributes.split(",");

      if (attributesArray.includes(attribute)) {
        attributesArray = attributesArray.filter((attr) => attr !== attribute);
      } else { 
        attributesArray.push(attribute);
      }
    } else { 
      attributesArray.push(attribute);
    }

    searchParams.delete("a");

    if (attributesArray.length > 0) {
      searchParams.append("a", attributesArray.join(","));
    }

    setSearchParams(searchParams);
  };
  function getObjectById(id) {
    return filters?.filters?.find(
      (attribute) => attribute?.attribute_id === id
    );
  }

 
  const handleDiscount = () => {
    setSearchDisabled(true);
    setPriceDisabled(true);

    searchParams.delete("page");
    if (discount) {
      searchParams.delete("d");
    } else {
      searchParams.set("d", 1);
    }
    setDiscount(!discount);
    setSearchParams(searchParams);

    setSearchDisabled(false);
    setPriceDisabled(false);
  };
  useEffect(() => {
    setDiscount(searchParams.get("d"));
  }, []);
 
  const handleModal = (item) => {
    if (typeof item !== "string") {
      setAtribute(true);

      const result = getObjectById(item.id);

      setOpenModal(result);
    }
    if (item === langData?.product?.color?.[currentLanguage]) {
      setColor(true);
    }
    if (item === langData?.product?.cost?.[currentLanguage]) {
      setCost(true);
    }
    if (item === langData?.product?.manufactor?.[currentLanguage]) {
      setManufactures(true);
    }
    if (item === langData?.product?.sorting?.[currentLanguage]) {
      setSorting(true);
    }
  };


 const handleAddAttribute = (item) => { 
    if (!activeAttribute.includes(item)) {
        setActiveAtribute((prev) => [...prev, item]);
    }
};

const handleRemoveAttribute = (item) => {  
    setActiveAtribute((prev) => prev.filter(attr => attr !== item));
};

const handleRemoveAttributeClose = (item) => {  
    const sortingItem = langData?.product?.sorting?.[currentLanguage];
    const costItem = langData?.product?.cost?.[currentLanguage];
    const manufactorItem = langData?.product?.manufactor?.[currentLanguage];
    const colorItem = langData?.product?.color?.[currentLanguage];

    setActiveAtribute((prev) => prev.filter(attr => attr !== item));
    searchParams?.delete("page");
    
    if(sortingItem === item){
        searchParams.delete('s')
    }
    if(costItem === item){
        searchParams.delete('mx')
        searchParams.delete('mn')
    }
    if(manufactorItem === item){
        searchParams.delete('b') 
    }
    if(colorItem === item){
        searchParams.delete('a') 
    }
    setSearchParams(searchParams);
};

useEffect(() => {
    const params = Object.fromEntries([...searchParams]);

    // Sorting
    const sortingItem = langData?.product?.sorting?.[currentLanguage];
    if (params?.s) {
        handleAddAttribute(sortingItem);
    } else {
        handleRemoveAttribute(sortingItem);
    }

    // Cost
    const costItem = langData?.product?.cost?.[currentLanguage];
    if (params?.mn && params?.mx || params?.d) {
        handleAddAttribute(costItem);
    } else {
        handleRemoveAttribute(costItem);
    }

    // Manufacturer
    const manufactorItem = langData?.product?.manufactor?.[currentLanguage];
    if (params?.b) {
        handleAddAttribute(manufactorItem);
    } else {
        handleRemoveAttribute(manufactorItem);
    }

    // Color
    const colorItem = langData?.product?.color?.[currentLanguage];
    if (params?.a) {
        handleAddAttribute(colorItem);
    } else {
        handleRemoveAttribute(colorItem);
    }

}, [searchParams]);


 
  return (
    <>
      {loading ? (
        <LoadContent containerClassName={styles.categoryAtributeLoad} />
      ) : (
        <>
          <div className={styles.categoryAtribute}>
            <div className={styles.categoryAtributeFix}>
              <FilterIcon onClick={() => setOpenModal({ name_en: "filter" })} />
            </div>
            <div className={styles.categoryContainer}>
              <span
                onClick={() => {
                  setSorting(true);
                }}
              >
                {langData?.product?.sorting?.[currentLanguage]}
              </span>
              <span onClick={() => setCost(true)}>
                {langData?.product?.cost?.[currentLanguage]}
              </span>
              {filters?.brandList?.length && (
                <span onClick={() => setManufactures(true)}>
                  {langData?.product?.manufactor?.[currentLanguage]}
                </span>
              )}
              {filters?.filters?.find((filter) => filter.attribute_id === 3)
                ?.values?.length > 0 && (
                <span onClick={() => setColor(true)}>
                  {langData?.product?.color?.[currentLanguage]}
                </span>
              )}
              {filters?.filters?.map(
                (item) =>
                  !!item?.show_in_mobile && (
                    <span
                      key={item.id}
                      onClick={() => {
                        setOpenModal(item);
                        setAtribute(true);
                      }}
                    >
                      {item?.["name_" + currentLanguage]}
                    </span>
                  )
              )}
            </div>
          </div>

          {!!activeAttribute?.length && (
            <div className={styles.categoryAtributeActive}>
              {[...new Set(activeAttribute)]?.map((item, index) => (
                <span
                  onClick={() => {
                    if (item?.styles && item?.styles === 1) {
                      setOpenModal({
                        name_en: "filter",
                      });
                    } else {
                      handleModal(item);
                    }
                  }}
                  key={index}
                >
                  {typeof item === "object"
                    ? item?.["name_" + currentLanguage]
                    : item}
                  <CloseIcon
                    onClick={(event) => {
                      // here
                      event.stopPropagation();
                      handleRemoveAttributeClose(item);
                    }}
                  />
                </span>
              ))}
            </div>
          )}
          {/* {searchParams.get("st") && (
            <div className={styles.categoryAtributeActive}>
              <span>
                {searchParams.get("st")}
                <CloseIcon
                  onClick={(event) => {
                    searchParams.delete("st");
                    setSearchParams(searchParams);
                  }}
                />
              </span>
            </div>
          )} */}
        </>
      )}

      <Filter
        data={openModal}
        handleDiscount={handleDiscount}
        discount={discount}
        onClearData={setOpenModal}
        filters={filters}
        currentLanguage={currentLanguage}
        handleSelectAttribute={handleSelectAttribute}
        handleSelectBrand={handleSelectBrand}
        handleSelectSorting={handleSelectSorting}
        activeAttributes={activeAttributes}
        activeBrands={activeBrands}
        searchDisabled={searchDisabled}
        priceDisabled={priceDisabled}
        searchParams={searchParams}
      />
      <Colors
        data={openModal}
        activeAttributes={activeAttributes}
        color={color}
        filters={filters}
        setColor={setColor}
        onClearData={setOpenModal}
        currentLanguage={currentLanguage}
        handleSelectAttribute={handleSelectAttribute}
      />
      <CountryOfManufacture
        data={openModal}
        sorting={sorting}
        setSorting={setSorting}
        attribute={attribute}
        setAtribute={setAtribute}
        onClearData={setOpenModal}
        manufactures={manufactures}
        setManufactures={setManufactures}
        filters={filters}
        currentLanguage={currentLanguage}
        handleSelectAttribute={handleSelectAttribute}
        handleSelectBrand={handleSelectBrand}
        handleSelectSorting={handleSelectSorting}
        activeAttributes={activeAttributes}
        activeBrands={activeBrands}
        searchParams={searchParams}
      />
      {cost && (
        <Cost
          currentLanguage={currentLanguage}
          filters={filters}
          searchParams={searchParams}
          data={cost}
          setCost={setCost}
          onClearData={setOpenModal}
          priceDisabled={priceDisabled}
          discount={discount}
          handleDiscount={handleDiscount}
        />
      )}
    </>
  );
};

export default Attributes;
