import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useSearchParams } from "react-router-dom";

let timeOutId;

export default memo(function FilterItemPrice({ min, max, defaultPrices, disabled=false }) {
  const [minPriceRange, setMinPriceRange] = useState(defaultPrices?.min);
  const [maxPriceRange, setMaxPriceRange] = useState(defaultPrices?.max);
  const [searchParams, setSearchParams] = useSearchParams();
  
  const handleChangePrice = (props) => {
    setMinPriceRange(props.min);
    setMaxPriceRange(props.max);

    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      if (props.min && props.min !== defaultPrices.min) { 
        searchParams.set("mn", props.min);
      } else {
        searchParams.delete("mn");
      }
      if (props.max && props.max !== defaultPrices.max) { 
        searchParams.set("mx", props.max);
      } else {
        searchParams.delete("mx");
      }
      setSearchParams(searchParams);
    }, 500);
  };

  useEffect(() => {
    if (min !== minPriceRange) {
      setMinPriceRange(min);
    }
    if (max !== maxPriceRange) {
      setMaxPriceRange(max);
    }
  }, [min, max]);

  return (
    <div className={styles.container}>
      <div className={styles.priceInputs}>
        <input
          className={styles.priceInput}
          min={min}
          max={max}
          placeholder={min}
          type="number"
          value={minPriceRange}
          onChange={(e) => {
            setMinPriceRange(e.target.value);
          }}
          disabled={disabled}
        />
        <input
          className={styles.priceInput}
          min={min}
          max={max}
          placeholder={min}
          type="number"
          value={maxPriceRange}
          onChange={(e) => {
            setMaxPriceRange(e.target.value);
          }}
          disabled={disabled}
        />
      </div>

      <Slider
        style={{
          width: "92%",
          margin: "0 auto 5px",
        }}
        trackStyle={{
          backgroundColor: "#d10000",
        }}
        onChange={(e) => {
          handleChangePrice({
            min: e[0],
            max: e[1],
          });
        }}
        defaultValue={[Number(min), Number(max)]}
        value={[
          Number(minPriceRange || defaultPrices?.min),
          Number(maxPriceRange || defaultPrices?.max),
        ]}
        range
        min={Number(defaultPrices?.min)}
        max={Number(defaultPrices?.max)}
      />
    </div>
  );
});
