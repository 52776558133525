import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Title from "./components/Title";
import { Link } from "react-router-dom";
import lang from "lang/lang";
import { searchWsPage, searchWsPageMan } from "store/WsSearchSlice";
import BanerSlider from "components/BanerSlider";
import LoadContent from "components/LoadContent";
import ProductList from "./components/productList";
import Attributes from "./components/Attribute";
import { selectMain } from "store/selector";

const Category = () => {
  const { filters, isLoading } = useSelector(
    ({ WsSearchSlice }) => WsSearchSlice
  );
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector(selectMain);
  const [searchParams, setSearchParams] = useSearchParams();
  const { categoryId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const scrollElement = useRef(null);
  const [recordPage, setRecordPage] = useState(20);

  useEffect(() => {
    const info = {
      p: searchParams.get("p"),
      page: searchParams.get("page"),
      s: searchParams.get("s"),
      a: searchParams.get("a"),
      b: searchParams.get("b"),
      c: searchParams.get("c"),
      d: searchParams.get("d") || "",
      mn: searchParams.get("mn"),
      mx: searchParams.get("mx"),
      st: searchParams.get("st"),
    };
    const filteredInfo = Object.fromEntries(
      Object.entries(info).filter(
        ([key, value]) => value !== null && value !== ""
      )
    );
    dispatch(searchWsPageMan(categoryId, filteredInfo));
  }, [searchParams]);

  

  const handleMoreProducts = (number) => {
    setCurrentPage(1);
    searchParams.set("p", number);
    setSearchParams(searchParams);
  };
  const handleMoreProduct = () => {
    setCurrentPage(1);
    searchParams.set("p", filters?.products?.length + recordPage);
    setSearchParams(searchParams); 
  };
  useEffect(() => {
    scrollElement?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }, [setSearchParams]);
  useEffect(() => {
    if (!searchParams.size) {
      setCurrentPage(1)
      window.scrollTo(0, 0);
    }
  }, [categoryId, searchParams.size]);
  return (
    <div className="wrapper" style={{ overflowX: "visible" }}>
      <div className={styles.category}>
        <Title
          category={filters?.category}
          styles={styles}
          countProduct={filters?.productCount}
        />

        <div className={styles.categorySlider}>
          <BanerSlider
            bannerSliders={filters?.category?.slider_images}
            containerStyle={{
              margin: 0,
              padding: 0,
              position: "relative",
            }}
            loop
            loading={isLoading}
          />
        </div>

        {isLoading ? (
          <LoadContent containerClassName={styles.categoryBrandLoad} />
        ) : (
          <>
            {filters?.category?.children?.length > 1 && (
              <div className={styles.categoryBrand} >
                {!!filters?.category?.children?.length &&
                  filters?.category?.children?.map((item, index) => (
                    <Link
                      to={`${
                        currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/category/filter/${item?.slug}${
                        filters?.brand ? `?b=${filters?.brand?.id}` : ""
                      }`}
                    >
                      <div key={index} className={styles?.categoryBrandItem}>
                        <div className={styles.categoryItemTop}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              item?.image?.image
                            }
                            alt="product"
                          />
                        </div>
                        <div className={styles.categoryItemBottom}>
                          <span>{item?.["name_" + currentLanguage]}</span>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            )}
          </>
        )}
        <div className={styles.example} ref={scrollElement}/>
        <Attributes
          styles={styles}
          setCurrentPage={setCurrentPage}
          filters={filters}
          loading={isLoading}
          currentLanguage = {currentLanguage}
        />
        {filters?.products  ? (
          <ProductList
            styles={styles}
            category={filters}
            loading={isLoading} 
            currentPage={currentPage}
            handleMoreProducts={handleMoreProducts}
            handleMoreProduct={handleMoreProduct}
            recordPage={recordPage}
            searchParams = {searchParams}
            setSearchParams = {setSearchParams}
            setRecordPage={setRecordPage}
            handlePagination={(e) => {
              searchParams.set("page", e);
              setSearchParams(searchParams);
              setCurrentPage(e); 
            }}
          />
        ) : filters?.category ? (
          <span>{lang[currentLanguage].product_not_found}</span>
        ) : null}
      </div>
    </div>
  );
};

export default Category;
