import Drawer from "components/Drawer";
import styles from "./styles.module.css";
import { useEffect, useState } from "react"; 
import langData from "lang/data"; 

const Colors = ({
  onClearData,
  setColor,
  currentLanguage,
  filters,
  activeAttributes,
  color,
  handleSelectAttribute
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(false);
    onClearData({});
    setColor(false);
  };
  useEffect(() => {
    if (color) {
      setIsOpen(true);
    }
  }, [color]);
  return (
    <Drawer
      title={langData.product.color?.[currentLanguage]}
      onClick={onClick}
      isOpen={isOpen}
      width="599px"
      height="100dvh"
      filter="1"
      top="0px"
    >
      <div className={styles.colors}>
        {filters?.filters?.map((item) => {
          if (item.attribute_id === 3) {
            return  item?.values?.map((colorItem,index) => (
                <div className={styles.colorsItem} key={index}>
                <div
                  className={
                    activeAttributes?.includes(
                      "3_" + colorItem?.color?.attribute_value_id
                    )
                      ? `${styles.colorsItemCircle} ${styles.active}`
                      : styles.colorsItemCircle
                  }
                >
                  <span
                    onClick={() => handleSelectAttribute(item, colorItem)}
                    className={styles.circle}
                    style={{ backgroundColor: colorItem?.value_en }}
                  />
                </div>
                <span
                  onClick={() => handleSelectAttribute(item, colorItem)}
                  className={
                    activeAttributes?.includes(
                      "3_" + colorItem?.color?.attribute_value_id
                    )
                      ? `${styles.circleText} ${styles.circleTextActive}`
                      : styles.circleText
                  }
                >
                  {colorItem?.color[`name_${currentLanguage}`]}
                </span>
              </div>
            )) 
          }
        })}
      </div>
    </Drawer>
  );
};

export default Colors;
