import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import SortBtnSvg from "../../assets/SortBtnSvg";
import ListSvg from "../../assets/ListSvg";
 
import GridSvg from "../../assets/GridSvg";
import sortTypes from "../../sort_types";
import { useDispatch, useSelector } from "react-redux";
import lang from "../../../../../../lang/lang";
import {
  setListType,
  setSortType,
} from "../../../../../../store/SearchPageSlice";
import UseOutSideClick from "../../../../../../helpers/UseOutSideClick";
import { useSearchParams } from "react-router-dom";
import { DATA } from "screens/Category/data";

export default function HeaderSortBlock({handleSelectSorting}) {

  const [showSortTypes, setShowSortTypes] = useState(false);
  const [titleSort,setTitleSort] = useState('')
  const { currentLanguage } = useSelector(({ main }) => main);
  const { sortType, listType } = useSelector(({ searchPage }) => searchPage);
  const dispatch = useDispatch();
  const sortModalRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  UseOutSideClick(sortModalRef, () => setShowSortTypes(false));

 


  return (
    <div className={styles.header}>
      <div className={styles.sortBlock}>
        <span className={styles.sortTitle}>
          {lang[currentLanguage]["Sorting by"]}:
        </span>
        <span className={styles.sortType}>
          {sortTypes.find((el) => 
             el.value === searchParams.get('s'))?.["label_" + currentLanguage]
          }
        </span>
        <div className={styles.sortBtnContainer}>
          <div
            className={styles.sortBtn}
            onClick={() => setShowSortTypes(!showSortTypes)}
          >
            <SortBtnSvg />
          </div>
          {showSortTypes && (
            <div className={styles.sortModal} ref={sortModalRef}>
              {DATA.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={styles.sortModalItem}
                    onClick={() => {
                      setTitleSort(item["label_" + currentLanguage])
                      handleSelectSorting(item?.value) 
                    }}
                  >
                    {item["label_" + currentLanguage]}
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className={styles.listTypeBlock}>
        {/* {window.innerWidth > 470 && (
          <div
            onClick={() => dispatch(setListType("list"))}
            className={styles.listTypeBtn}
            style={listType == "list" ? { backgroundColor: "#d10000" } : null}
          >
            <ListSvg />
          </div>
        )} */}

        <div
          onClick={() => dispatch(setListType("grid"))}
          className={styles.listTypeBtn}
          style={listType == "grid" ? { backgroundColor: "#d10000" } : null}
        >
          <GridSvg />
        </div>
      </div>
    </div>
  );
}
