import { createSlice } from '@reduxjs/toolkit'; 
import axios from "axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  filters: [],
  filter: null,
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetCart(state) {
        state.checkout.activeStep = 0;
        state.checkout.cart = [];
        state.checkout.total = 0;
        state.checkout.subtotal = 0;
        state.checkout.discount = 0;
        state.checkout.shipping = 0;
        state.checkout.billing = null;
      },

    getFiltersSuccess(state, action) { 
      state.isLoading = false;
      state.filters = action.payload;
    }, 
 
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getFiltersSuccess,resetCart, getFilterPagination } = slice.actions;

// ----------------------------------------------------------------------
 
export function searchWsPage(WSsearchInfo,navigate) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading()); 
        try { 
                const categorySlug = WSsearchInfo?.category?.slug ?? 0 
                const brandId = WSsearchInfo?.brand?.id  ?? 0;
                const search = WSsearchInfo?.search  ?? 0; 
                const brandSlug = WSsearchInfo?.brand?.slug  ?? 0; 
                const formData = new FormData();
                if(categorySlug){
                    formData.append('slug',categorySlug); 
                }
                if(brandId){
                    formData.append('b', brandId); 
                }
                if(search){
                    formData.append('search', search); 
                    formData.append('searchInfo', 1); 
                }
                 
                
                if(categorySlug){
                    const response = axios.post(`http://127.0.0.1:8000/api/category/${categorySlug}`, formData);  
                    dispatch(slice.actions.getFiltersSuccess((await response).data));
                    navigate(`category/filter/${categorySlug}`)
                }else if(brandId && brandSlug){
                    const response = axios.post(`http://127.0.0.1:8000/api/brand/${brandSlug}`, formData);
                    dispatch(slice.actions.getFiltersSuccess((await response).data));
                    navigate(`brand/${brandSlug}/category`)  
                }else{
                    const response = axios.post(`http://127.0.0.1:8000/api/category/${search}`, formData);  
                    dispatch(slice.actions.getFiltersSuccess((await response).data));
                    navigate(`category/filter/${WSsearchInfo?.search}`)
                } 
   
                
          
        //   const response = await axios.get(`/filter/all?page=${page}`);
        //   dispatch(slice.actions.getFilterPagination(response.data.filters));
        } catch (error) {
          console.error('Error fetching filter:', error);
          dispatch(slice.actions.hasError(error.response?.data || 'Error fetching filter'));
        }
      };
      
}

export function searchWsPageMan(slug,info) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading()); 
        try {

                const categorySlug = slug ?? 0 
               
                const formData = new FormData();
                if(categorySlug){
                    formData.append('slug',categorySlug); 
                }
                for (const [key, value] of Object.entries(info)) {
                    if (value !== null && value !== "") { 
                        formData.append(key, value);
                    }
                }
                 
                
                if(categorySlug){
                    const response = axios.post(`http://127.0.0.1:8000/api/category/${categorySlug}`, formData);  
                    dispatch(slice.actions.getFiltersSuccess((await response).data)); 
                } 
   
                 
        } catch (error) {
          console.error('Error fetching filter:', error);
          dispatch(slice.actions.hasError(error.response?.data || 'Error fetching filter'));
        }
      };
      
}
 
export function getFilters(page = 1) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/filter/all?page=${page}`);
      dispatch(slice.actions.getFilterPagination(response.data.filters));
    } catch (error) {
      console.error('Error fetching filter:', error);
      dispatch(slice.actions.hasError(error.response?.data || 'Error fetching filter'));
    }
  };
}
 
 
 
 
