import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  grouped_products: [],
  imagesPath: "",
  loading_grouped_products: false,
  error: null,
};

export const getGroupedProductsRequest = createAsyncThunk(
  "get_grouped_products/get_grouped_products_request",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/grouped-products`,
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          token: process.env.REACT_APP_TOKEN,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const getGroupedProductsSlice = createSlice({
  name: "get_grouped_products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getGroupedProductsRequest.pending, (state) => {
        state.loading_grouped_products = true;
        state.error = null;
      })

      .addCase(getGroupedProductsRequest.fulfilled, (state, { payload }) => {
        const { data, imagesPath } = payload;
        state.grouped_products = data;
        state.imagesPath = imagesPath;
        state.loading_grouped_products = false;
      })

      .addCase(getGroupedProductsRequest.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading_grouped_products = false;
      });
  },
});

export default getGroupedProductsSlice.reducer;
