import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Drawer from "components/Drawer";
import DropDownAttribute from "screens/Category/components/DropDounAttribute";
import FilterItemPrice from "../filterPrice/index";
import { DATA } from "../../../../data";
import langData from "lang/data"; 
import checkBase64 from "helpers/checkBase64";
import Switch from "../switch";
import lang from "lang/lang";
import DiagonalAttributeRange from "../diagonalAttributeRange";

const Filter = ({
  data,
  onClearData,
  filters,
  currentLanguage,
  handleSelectAttribute,
  handleSelectBrand,
  activeAttributes,
  activeBrands,
  handleSelectSorting,
  searchParams,
  handleDiscount,
  discount,
  priceDisabled,
  searchDisabled, 
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [search, setSearch] = useState("");
  useEffect(() => {
    if (searchParams.get("st") === null) {
      setSearch("");
    }
  }, [searchParams]);

  useEffect(() => {
    if (data?.name_en === "filter") {
      setIsOpen(true);
    }
  }, [data?.name_en]);

  const onClick = () => {
    setIsOpen(false);
    onClearData({});
  };

  return (
    <Drawer
      filter
      title={langData?.product?.filter?.[currentLanguage]}
      onClick={onClick}
      isOpen={isOpen}
      height="100dvh"
      top="0px"
    >
      <div className={styles.filter}>
        <div className={styles.container}>
          <div className={styles.costContainer}>
            <p>{lang[currentLanguage]?.search}</p>
            <input
              style={{
                width: "100%",
                height: "40px",
                marginBottom: "30px",
                border: "solid #ccc 1px",
                outline: "none",
                padding: "0 10px",
              }}
              placeholder={lang[currentLanguage]?.search}
              value={search}
              //   onChange={(e) => handleSearch(e.target.value)}
              disabled={searchDisabled}
              id="main_search"
            />
          </div>
          {!!filters?.discount && (
            <div className={styles.switch}>
              <p>{langData.product.discount?.[currentLanguage]}</p>
              <Switch
                isOn={!discount}
                handleToggle={(e) => {
                  handleDiscount();
                }}
              />
            </div>
          )}
          <div className={styles.costContainer}>
            <p>{langData.product.cost?.[currentLanguage]}</p>
            <div className={styles.cost}>
              <FilterItemPrice
                min={searchParams.get("mn") ?? filters?.minPrice}
                max={searchParams.get("mx") ?? filters?.maxPrice}
                defaultPrices={{
                  min: filters?.originalMinPrice,
                  max: filters?.originalMaxPrice,
                }}
                disabled={priceDisabled} 
              />
            </div>
          </div>
          <DropDownAttribute
            checked={searchParams.get("s")}
            title={langData?.product.sorting?.[currentLanguage]}
          >
            <div className={styles.sort}>
              <form>
                {DATA?.map((item, key) => (
                  <div className={styles.sortingItem} key={key}>
                    <p className={styles.checkboxWrapper}>
                      <span onClick={() => handleSelectSorting(item?.value)}>
                        {item?.["label_" + currentLanguage]}
                      </span>
                      <input
                        type="checkbox"
                        id={item?.value}
                        name="radio-group"
                        checked={searchParams.get("s") == item?.value}
                      />
                      <label
                        for={item?.value}
                        onClick={() => handleSelectSorting(item?.value)}
                      />
                    </p>
                  </div>
                ))}
              </form>
            </div>
          </DropDownAttribute>
          {filters?.brandList && (
            <DropDownAttribute
              checked={searchParams.get("b")}
              title={langData?.product.manufactor?.[currentLanguage]}
            >
              <div className={styles.manufactur}>
                <form>
                  {filters?.brandList?.map((item, index) => (
                    <div key={index} className={styles.sortingItem}>
                      <p className={styles.checkboxWrapper}>
                        <div className={styles.brandImg}>
                          <img
                            onClick={() => handleSelectBrand(item)}
                            src={
                              item?.logo
                                ? checkBase64(item?.logo)
                                  ? "data:image/png;base64," + item.logo
                                  : process.env.REACT_APP_STORAGE_URL +
                                    item.logo
                                : "https://vlv.am/public/frontend/default/img/brand_image.png"
                            }
                            alt="brand"
                          />
                        </div>
                        <input
                          type="checkbox"
                          id={item?.id}
                          name="radio-group"
                          checked={activeBrands?.includes(item?.id)}
                        />
                        <label
                          onClick={() => handleSelectBrand(item)}
                          for={item?.id}
                        />
                      </p>
                    </div>
                  ))}
                </form>
              </div>
            </DropDownAttribute>
          )}

          {filters?.filters?.map((item) => {
            if (item.attribute_id === 3) {
              return (
                <DropDownAttribute
                  key={item?.id}
                  checked={item?.values?.some((el) => {
                    return activeAttributes?.includes(
                      `${item.attribute_id}_${el.id}`
                    );
                  })}
                  title={langData.product.color?.[currentLanguage]}
                >
                  {item?.values?.map((colorItem) => (
                    <div key={colorItem?.id} className={styles.colorsItem}>
                      <div
                        className={`
                        ${styles.colorsItemCircle} 
                        ${
                          activeAttributes?.includes(
                            "3_" + colorItem?.color?.attribute_value_id
                          )
                            ? styles.active
                            : ""
                        }
                     `}
                      >
                        <span
                          onClick={() => handleSelectAttribute(item, colorItem)}
                          className={styles.circle}
                          style={{ backgroundColor: colorItem?.value_en }}
                        />
                      </div>
                      <span
                        onClick={() => handleSelectAttribute(item, colorItem)}
                        className={`
                                ${styles.circleText} 
                                ${
                                  activeAttributes?.includes(
                                    colorItem?.color?.attribute_value_id
                                  )
                                    ? styles.circleTextActive
                                    : ""
                                }
                            `}
                      >
                        {colorItem?.color[`name_${currentLanguage}`]}
                      </span>
                    </div>
                  ))}
                </DropDownAttribute>
              );
            }

            return (
              <DropDownAttribute
                key={item?.id}
                checked={item?.values?.some((el) => {
                  return activeAttributes?.includes(
                    `${item.attribute_id}_${el.id}`
                  );
                })}
                title={item?.[`name_${currentLanguage}`]}
              >
                {item?.styles === 1 ? (
                  <DiagonalAttributeRange
                    attribute={item}
                    activeAttributes={activeAttributes}
                    handleSelectAttribute={handleSelectAttribute}
                    // handleDeleteAttribute={handleDeleteAttribute}
                  />
                ) : (
                  <div className={styles.manufactur}>
                    <form>
                      {item?.values?.map((value) => (
                        <div key={value?.id} className={styles.sortingItem}>
                          <p className={styles.checkboxWrapper}>
                            <span
                              onClick={() => handleSelectAttribute(item, value)}
                            >
                              {value?.[`value_${currentLanguage}`]}
                            </span>
                            <input
                              type="checkbox"
                              id={value?.id}
                              name="radio-group"
                              checked={activeAttributes?.includes(
                                `${item?.attribute_id}_${value?.id}`
                              )}
                            />
                            <label
                              onClick={() => handleSelectAttribute(item, value)}
                              htmlFor={value?.id}
                            />
                          </p>
                        </div>
                      ))}
                    </form>
                  </div>
                )}
              </DropDownAttribute>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default Filter;
