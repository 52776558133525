import React, { memo, useEffect, useState } from "react";
import styles from "./GroupPurchaseOfProducts.module.css";
import FromToPage from "components/FromToPage";
import langData from "lang/data";
import { useDispatch, useSelector } from "react-redux";
import { getGroupedProductsRequest } from "./requests/getGroupedProductsSlice";
import GroupProductPlusIcon from "assets/SVG/GroupProductPlusIcon";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import UseCalcPrice from "helpers/UseCalcPrice";
import { Navigation, Pagination } from "swiper/modules";
import checkBase64 from "helpers/checkBase64";
import { credit36Month } from "helpers/creditCalculator";
import ShopCartSvg from "assets/SVG/ShopCartSvg";
import "./GroupPurchaseOfProducts.css";
import GroupSaleStarIcon from "assets/SVG/GroupSaleStarIcon";
import LoadContent from "components/LoadContent";
import useWindowSize from "components/useWindowSize";
import lang from "lang/lang";
import GroupCreditModal from "./components/GroupCreditModal";

const GroupPurchaseOfProducts = () => {
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();
  const [showCreditModal, setShowCreditModal] = useState({
    type: false,
    item: {},
    id: null,
  });
  const { currentLanguage, currentCurrency } = useSelector(({ main }) => main);
  const { loading_grouped_products, grouped_products } = useSelector(
    ({ getGroupedProductsSlice }) => getGroupedProductsSlice
  );
  useEffect(() => {
    dispatch(getGroupedProductsRequest());
  }, [dispatch]);

  const calculateFinalPrice = (price) => {
    if (
      (grouped_products?.product?.products?.categories?.[0]?.installation !==
        0 &&
        grouped_products?.product?.products?.categories?.[0]?.installing ===
          0) ||
      (grouped_products?.categories?.[0]?.installation !== 0 &&
        grouped_products?.categories?.[0]?.installing === 0)
    ) {
      return (
        price - grouped_products?.product?.products?.installing_price ||
        grouped_products?.products?.installing_price
      );
    }
    return price;
  };

  const calculatePrice = (
    discount_type,
    selling_price,
    discount,
    date_discount_start,
    date_discount_end
  ) => {
    const currentDate = new Date();
    const date_discount_start_of_local = new Date(date_discount_start);
    const date_discount_end_of_local = new Date(date_discount_end);

    if (
      discount_type === 1 &&
      currentDate >= date_discount_start_of_local &&
      currentDate <= date_discount_end_of_local
    ) {
      return selling_price - discount;
    } else if (
      discount_type === 0 &&
      currentDate >= date_discount_start_of_local &&
      currentDate <= date_discount_end_of_local
    ) {
      const discountAmount = (selling_price * discount) / 100;
      return selling_price - discountAmount;
    }

    return selling_price;
  };

  const calculateDiscountPercent = (discount_type, selling_price, discount) => {
    if (discount_type === 1) {
      return ((discount / selling_price) * 100).toFixed(0);
    } else if (discount_type === 0) {
      return discount.toFixed(0);
    }

    return 0;
  };

  useEffect(() => {
    if (showCreditModal.type !== false) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showCreditModal.type]);

  return (
    <div className={"wrapper"}>
      <FromToPage
        pages={[
          {
            label:
              langData.header.menu.GroupPurchaseOfProducts[currentLanguage],
            value: "/about-us",
            active: true,
          },
        ]}
      />

      <div className={styles.Container}>
        {loading_grouped_products
          ? Array.from({ length: 2 }).map((_, ind) => (
              <div
                className={styles.GroupPurchaseOfProducts_renderedContainer}
                key={ind}>
                <div className={styles.ProductsPatents}>
                  <LoadContent
                    containerClassName={styles.GroupProductItem}
                    height={windowWidth < 768 ? 170 : 250}
                  />
                  <LoadContent
                    containerClassName={styles.GroupProductPlusIcon}
                    containerStyle={{
                      height: windowWidth < 1024 ? 14 : 55,
                      width: windowWidth < 1024 ? 14 : 55,
                      borderRadius: "50%",
                    }}
                  />
                  <LoadContent
                    containerClassName={styles.GroupProductItem}
                    height={windowWidth < 768 ? 170 : 250}
                  />
                  <LoadContent
                    containerClassName={styles.GroupProductPlusIcon}
                    containerStyle={{
                      height: windowWidth < 1024 ? 14 : 55,
                      width: windowWidth < 1024 ? 14 : 55,
                      borderRadius: "50%",
                    }}
                  />
                  <LoadContent
                    containerClassName={styles.GroupProductItem}
                    height={windowWidth < 768 ? 170 : 250}
                  />
                </div>
                <div className={styles.GroupInfoBlock}>
                  <LoadContent containerStyle={{ width: 200, height: 40 }} />

                  <div className={styles.GroupCreditContainer}>
                    <LoadContent
                      containerStyle={{
                        width: "30%",
                        height: "100%",
                      }}
                    />
                    <LoadContent
                      containerStyle={{
                        width: "30%",
                        height: "100%",
                      }}
                    />
                    <LoadContent
                      containerStyle={{
                        width: "30%",
                        height: "100%",
                      }}
                    />
                  </div>
                  <div className={styles.GroupBuyContainer}>
                    <LoadContent
                      containerStyle={{
                        width: 140,
                        height: 40,
                      }}
                    />

                    <LoadContent
                      containerStyle={{
                        width: 140,
                        height: 40,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))
          : grouped_products?.map((productList, index) => (
              <div
                key={index}
                className={styles.GroupPurchaseOfProducts_renderedContainer}>
                <div className={styles.ProductsPatents}>
                  {productList?.products?.map((product, ind) => (
                    <React.Fragment key={product.id}>
                      <div className={styles.GroupProductItem}>
                        <div className={styles.InCardSlider}>
                          <Swiper
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            modules={[Pagination, Navigation]}
                            loop={false}
                            className={styles.product__wrapper + " paginate"}>
                            {product?.gallary_images_api?.length > 0 ? (
                              product.gallary_images_api.map(
                                (gallery, index) => (
                                  <SwiperSlide key={gallery.id}>
                                    <Link
                                      to={`/${
                                        currentLanguage !== "hy"
                                          ? "/" + currentLanguage
                                          : ""
                                      }/Product/${product.id}`}>
                                      <img
                                        className={styles.GroupSliderImages}
                                        alt={
                                          product.brand?.name +
                                          " " +
                                          product.product_name
                                        }
                                        src={
                                          process.env.REACT_APP_STORAGE_URL +
                                          gallery.images_source
                                        }
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = require("../../assets/defaultImg.png");
                                        }}
                                      />
                                    </Link>
                                  </SwiperSlide>
                                )
                              )
                            ) : (
                              <SwiperSlide key={index}>
                                <Link
                                  to={`${
                                    currentLanguage !== "hy"
                                      ? "/" + currentLanguage
                                      : ""
                                  }/Product/${product.id}`}>
                                  <img
                                    className={styles.GroupSliderImages}
                                    alt="noImage"
                                    src={require("../../assets/defaultImg.png")}
                                  />
                                </Link>
                              </SwiperSlide>
                            )}
                          </Swiper>
                        </div>
                        <div className={styles.descContainer}>
                          <Link
                            to={`${
                              currentLanguage !== "hy"
                                ? "/" + currentLanguage
                                : ""
                            }/brand/${product?.brand?.slug}/category`}>
                            <img
                              className={styles.brandImg}
                              src={
                                checkBase64(product?.brand?.logo)
                                  ? "data:image/png;base64," +
                                    product?.brand?.logo
                                  : process.env.REACT_APP_STORAGE_URL +
                                    product?.brand?.logo
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.style.maxHeight = "20px";
                                currentTarget.src = require("../../assets/defaultImg.png");
                              }}
                              alt={
                                product?.brand?.name +
                                " " +
                                product?.product_name
                              }
                            />
                          </Link>
                          <Link
                            to={`${
                              currentLanguage !== "hy"
                                ? "/" + currentLanguage
                                : ""
                            }/Product/${product?.id}`}>
                            <div className={styles.categoryName}>
                              {product?.categories?.[0]?.[
                                "name_" + currentLanguage
                              ] ||
                                product?.categories?.[0]?.[
                                  "name_" + currentLanguage
                                ]}
                            </div>
                            <p className={styles.productName}>
                              {product?.brand?.name +
                                " " +
                                product?.product_name}
                            </p>
                            {product?.cashback ? (
                              <div className={styles.cashbackContainer}>
                                <span className={styles.cashbackText}>
                                  Cashback
                                </span>
                                <span
                                  className={styles.cashbackPrice}
                                  style={{ marginLeft: "5px" }}>
                                  {UseCalcPrice(
                                    product?.cashback,
                                    currentCurrency
                                  )}
                                </span>
                              </div>
                            ) : null}
                            {credit36Month(
                              product?.promo_price ||
                                (product?.online_price &&
                                  product?.online_selling_price) ||
                                product?.skus[0]?.selling_price
                            ) ? (
                              <div className={styles.priceMounth}>
                                {UseCalcPrice(
                                  credit36Month(
                                    product?.promo_price ||
                                      (product?.online_price &&
                                        product?.online_selling_price) ||
                                      product?.skus[0]?.selling_price
                                  ),
                                  currentCurrency
                                )}{" "}
                                / {langData.month[currentLanguage]}
                              </div>
                            ) : (
                              <div style={{ height: "24px" }} />
                            )}

                            {!product?.promo_price &&
                            !(
                              product?.online_price &&
                              product?.online_selling_price
                            ) ? (
                              <p className={styles.price}>
                                {UseCalcPrice(
                                  calculateFinalPrice(
                                    product?.average_price ||
                                      product?.skus[0]?.selling_price
                                  ),
                                  currentCurrency
                                )}
                              </p>
                            ) : (
                              <div className={styles.promoPriceContainer}>
                                <p className={styles.newPrice}>
                                  {UseCalcPrice(
                                    calculateFinalPrice(
                                      product?.promo_price ||
                                        (product?.online_price &&
                                          product?.online_selling_price)
                                    ),
                                    currentCurrency
                                  )}
                                </p>
                                <div className={styles.oldPriceContainer}>
                                  <p className={styles.oldPrice}>
                                    {UseCalcPrice(
                                      calculateFinalPrice(
                                        product?.skus[0]?.selling_price
                                      ),
                                      currentCurrency
                                    )}
                                  </p>

                                  <span className={styles.line} />
                                </div>
                              </div>
                            )}
                          </Link>
                        </div>
                      </div>
                      {ind < 2 ? (
                        <GroupProductPlusIcon
                          className={styles.GroupProductPlusIcon}
                        />
                      ) : null}
                    </React.Fragment>
                  ))}
                </div>

                <div className={styles.GroupInfoBlock}>
                  <div className={styles.GroupPriceAndSale}>
                    {calculateDiscountPercent(
                      productList?.general?.discount_type,
                      productList?.general?.selling_price,
                      productList?.general?.discount
                    ) > 0 && (
                      <div className={styles.GroupSaleParent}>
                        <GroupSaleStarIcon />
                        <p className={styles.GroupSalePercent}>
                          -
                          {calculateDiscountPercent(
                            productList?.general?.discount_type,
                            productList?.general?.selling_price,
                            productList?.general?.discount
                          )}
                          %
                        </p>
                      </div>
                    )}
                    <p className={styles.GroupPrice}>
                      {calculatePrice(
                        productList?.general?.discount_type,
                        productList?.general?.selling_price,
                        productList?.general?.discount,
                        productList?.general?.date_discount_start,
                        productList?.general?.date_discount_end
                      )}{" "}
                      ֏
                    </p>
                  </div>
                  <div className={styles.GroupCreditContainer}>
                    <div>
                      <p className={styles.GroupCreditTitle}>
                        {lang[currentLanguage].cash}
                      </p>
                      <p className={styles.GroupCreditPrice}>60.000 ֏</p>
                    </div>
                    <div>
                      <p className={styles.GroupCreditTitle}>36 ամիս</p>
                      <p className={styles.GroupCreditPrice}>80.000 ֏</p>
                    </div>
                    <div>
                      <p className={styles.GroupCreditTitle}>48 ամիս</p>
                      <p className={styles.GroupCreditPrice}>80.000 ֏</p>
                    </div>
                  </div>
                  <div className={styles.GroupBuyContainer}>
                    <button
                      className={styles.GroupButtonBuyNow}
                      type={"button"}
                      onClick={() =>
                        setShowCreditModal({
                          type: "buyNow",
                          item: calculatePrice(
                            productList?.general?.discount_type,
                            productList?.general?.selling_price,
                            productList?.general?.discount,
                            productList?.general?.date_discount_start,
                            productList?.general?.date_discount_end
                          ),
                          id: productList?.general?.id,
                        })
                      }>
                      <ShopCartSvg className={styles.cardBtnSvg} />
                      {lang[currentLanguage].buy_now}
                    </button>

                    <button
                      className={styles.GroupButtonBuyOnCredit}
                      onClick={() => {
                        setShowCreditModal({
                          type: "credit",
                          item: calculatePrice(
                            productList?.general?.discount_type,
                            productList?.general?.selling_price,
                            productList?.general?.discount,
                            productList?.general?.date_discount_start,
                            productList?.general?.date_discount_end
                          ),
                          id: productList?.general?.id,
                        });
                      }}>
                      {lang[currentLanguage].buy_on_credit}
                    </button>
                  </div>
                </div>
              </div>
            ))}
      </div>
      <GroupCreditModal
        product={grouped_products.product}
        // choosenGift={choosenGift}
        modalType={showCreditModal.type}
        id={showCreditModal.id}
        closeModal={() => {
          setShowCreditModal({ item: {}, type: false });
        }}
        totalPrice={showCreditModal.item}
        qty={1}
      />
    </div>
  );
};

export default memo(GroupPurchaseOfProducts);
