import React, { memo, useCallback, useRef, useState, useEffect } from "react";
import "./StoriesModal.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import langData from "lang/data";
import StoryCross from "assets/SVG/StoryCross";
import StoryUnmute from "assets/SVG/StoryUnmute";
import StoryMute from "assets/SVG/StoryMute";
import StoryPause from "assets/SVG/StoryPause";
import StoryPlay from "assets/SVG/StoryPlay";
import StoryBuyNow from "assets/SVG/StoryBuyNow";
import { sandBuyNowRequest } from "./sandBuyNowSlice";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";

const StoriesModalComponent = ({
  isOpen,
  setIsOpen,
  selectedStoryIndex,
  handleFullScreenToggle,
  fullscreenDivRef,
}) => {
  const { get_stories, imagesPath } = useSelector(
    (state) => state.getStoriesSlice
  );
  const { currentLanguage } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoRefs = useRef([]);
  const swiperRef = useRef(null);
  const [isPaused, setIsPaused] = useState({}); // track play/pause state per video
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState({}); // track loading state

  const videoPlayPause = useCallback((index) => {
    const video = videoRefs.current[index];
    if (video) {
      if (video.paused) {
        video.play().catch((error) => {
          console.log("Playback failed:", error);
        });
        setIsPaused((prev) => ({ ...prev, [index]: false })); // set video as playing
      } else {
        video.pause();
        setIsPaused((prev) => ({ ...prev, [index]: true })); // set video as paused
      }
    }
  }, []);

  const videoMuteUnmute = useCallback(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.muted = !video.muted;
      }
    });
    setIsMuted((prev) => !prev);
  }, []);

  const handleSlideChange = useCallback(() => {
    if (swiperRef.current) {
      const currentIndex = swiperRef.current.swiper.realIndex;
      videoRefs.current.forEach((video, index) => {
        if (video && video.src) {
          if (index === currentIndex) {
            video.play().catch((error) => {
              console.error(`Failed to play video ${index}:`, error);
            });
            setIsPaused((prev) => ({ ...prev, [index]: false }));
          } else {
            video.pause();
            video.currentTime = 0;
            setIsPaused((prev) => ({ ...prev, [index]: true }));
          }
        }
      });
    }
  }, []);

  const stopAllVideos = () => {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });
  };

  const handleClose = useCallback(() => {
    stopAllVideos();
    setIsOpen(false);
    handleFullScreenToggle();
  }, [handleFullScreenToggle, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      handleSlideChange();
    }
    if (document.querySelector("jdiv")) {
      if (isOpen) {
        document.querySelector("jdiv").style.display = "none";
      } else {
        document.querySelector("jdiv").style.display = "block";
      }
    }
  }, [isOpen, handleSlideChange]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current?.swiper?.slideTo(selectedStoryIndex);
    }
  }, [selectedStoryIndex]);

  const handleVideoEnd = (index) => {
    if (swiperRef.current) {
      const totalSlides = swiperRef.current.swiper.slides.length;
      const currentIndex = swiperRef.current.swiper.realIndex;

      if (currentIndex < totalSlides - 1) {
        // Move to the next slide if it's not the last one
        swiperRef.current.swiper.slideNext();
      } else {
        // If it's the last slide, set the video to paused
        setIsPaused((prev) => ({ ...prev, [index]: true }));
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleBuyNow = useCallback(
    (story_id) => {
      dispatch(sandBuyNowRequest({ story_id }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleClose();
            navigate(
              `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/cart`
            );
          }
        });
    },
    [currentLanguage, dispatch, handleClose, navigate]
  );

  const handleVideoLoad = (index) => {
    setIsVideoLoading((prev) => ({ ...prev, [index]: false })); // Video has loaded

    // Start playing the video automatically after loading
    const video = videoRefs.current[index];
    if (video) {
      video.play().catch((error) => {
        console.error("Failed to start video playback:", error);
      });
      setIsPaused((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleVideoLoadStart = (index) => {
    setIsVideoLoading((prev) => ({ ...prev, [index]: true })); // Video is loading
  };

  if (!isOpen) {
    stopAllVideos();
  }

  return (
    <div
      className={`StoriesModal ${isOpen ? "is-open" : ""}`}
      ref={fullscreenDivRef}>
      <Swiper
        className={"StoriesContainer"}
        direction={"vertical"}
        ref={swiperRef}
        speed={500}
        grabCursor={true}
        onSlideChange={handleSlideChange}
        initialSlide={selectedStoryIndex}>
        {get_stories?.map((item, index) => {
          const nameTrimmed = item?.[`name_${currentLanguage}`]?.trim() || null;
          const descriptionTrimmed =
            item?.[`description_${currentLanguage}`]?.trim() || null;

          return (
            <SwiperSlide
              className={"StoryContentParent"}
              key={item.id}>
              {item.image === 1 ? (
                <img
                  src={imagesPath + "/" + item.images}
                  alt="story image_or_video"
                  className={"StoryVideoImageContent"}
                />
              ) : isOpen && swiperRef.current?.swiper?.realIndex === index ? (
                <>
                  {isVideoLoading[index] && (
                    <Spinner
                      size="xl"
                      color={"white"}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%,-50%)",
                        zIndex: 1,
                        width: 50,
                        height: 50,
                      }}
                    />
                  )}
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    src={`${imagesPath}${item.video_path}`}
                    className={"StoryVideoImageContent"}
                    muted={isMuted}
                    playsInline={true}
                    crossOrigin="anonymous"
                    autoPlay // Ensures autoplay
                    onLoadedData={() => handleVideoLoad(index)}
                    onLoadStart={() => handleVideoLoadStart(index)}
                    onEnded={() => handleVideoEnd(index)}></video>
                </>
              ) : (
                <div className="StoryVideoImageContent" />
              )}
              {!nameTrimmed && !descriptionTrimmed ? (
                <div />
              ) : (
                <div className="StoryInfoContainer">
                  <Link to={item?.url}>
                    <div className="StoryTextContainer">
                      {nameTrimmed && (
                        <h2 className="StoryTitle">{nameTrimmed}</h2>
                      )}
                      {descriptionTrimmed && (
                        <p className="StoryDescription">{descriptionTrimmed}</p>
                      )}
                    </div>
                  </Link>
                  {item.buy_now ? (
                    <button
                      className="StoryBuyNow"
                      onClick={() => handleBuyNow(item.id)}>
                      <StoryBuyNow className="StoryBuyNowIcon" />
                      {langData.buttons.buy_now[currentLanguage]}
                    </button>
                  ) : null}
                </div>
              )}

              <div className={"StoriesRightButtons"}>
                <button
                  className={"StoriesControlButtons"}
                  onClick={handleClose}>
                  <StoryCross className={"StoryIcon"} />
                </button>
                <div className={"StoriesBottomControlsContainer"}>
                  {item.image === 0 && (
                    <>
                      <button
                        className={"StoriesControlButtons"}
                        onClick={() => videoPlayPause(index)}>
                        {!isPaused[index] ? (
                          <StoryPause className={"StoryIcon"} />
                        ) : (
                          <StoryPlay className={"StoryIcon"} />
                        )}
                      </button>
                      <button
                        className={"StoriesControlButtons"}
                        onClick={videoMuteUnmute}>
                        {!isMuted ? (
                          <StoryUnmute className={"StoryIcon"} />
                        ) : (
                          <StoryMute className={"StoryIcon"} />
                        )}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export const StoriesModal = memo(StoriesModalComponent);
