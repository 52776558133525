import Drawer from "components/Drawer";
import styles from "./styles.module.css";
import FilterItemPrice from "../filterPrice/index";
import { useSelector } from "react-redux";
import { selectCategory, selectMain } from "store/selector";
import { useSearchParams } from "react-router-dom";
import { useState, useMemo } from "react";
import langData from "lang/data";
import Switch from "../switch";

const Cost = ({
  data,
  onClearData,
  setCost,
  currentLanguage,
  searchParams,
  filters,
  priceDisabled,
  handleDiscount,
  discount
}) => {
  const onClick = () => {
    setCost(false);
    onClearData(false);
  };

  return (
    <Drawer
      title={langData?.product?.cost?.[currentLanguage]}
      onClick={onClick}
      isOpen={data}
      width="599px"
      height="100dvh"
      filter="1"
      top="0px"
    >
      <div className={styles.cost}>
        <FilterItemPrice
          min={searchParams.get("mn") ?? filters?.minPrice}
          max={searchParams.get("mx") ?? filters?.maxPrice}
          defaultPrices={{
            min: filters?.originalMinPrice,
            max: filters?.originalMaxPrice,
          }}
          disabled={priceDisabled}
        />
        {!!filters?.discount && (
          <div className={styles.switch}>
            <p>{langData.product.discount?.[currentLanguage]}</p>
            <Switch
              isOn={!discount}
              handleToggle={(e) => {
                handleDiscount();
              }}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default Cost;
